/**
 * Components global config
 **/
(function(app, $) {
	var componentsConfig = {
		global: {
			components: {
				'global.all': {
					initlist: [
						'owlcarousel',
						'mediaplayer',
						'dynamicpromobanner',
						'validator',
						'pattern',
						'fancybox',
						'carouselrecommendation',
						'gtm',
						'mc',
						'wishlist',
						'lazyloadxt',
						'popuptoggler',
						'midnight',
						'accordion',
						'zoom'
					]
				},
				'global.fancybox': {},
				'global.customer': {},
				'account.fakelogin': {},
				'account.login': {},
				'global.firstvisitbanner': {
					enabled: !app.preferences.isIOSNativeApp
				},
				'global.countrydetectionblock': {},
				'global.footer': {},
				'global.navigation': {adjustLeftNavHeightOnResize: false},
				'global.header': {
					disablePaddingManagement : false,
					scrollConfig : {
						'cssClass' : 'b-minimized_header',
						'breakPoint' : 0
					},
					miniCartEmpty : "b-mini_cart-empty",
					miniCartEmptySel : ".b-mini_cart-empty"
				},
				'global.localeselector': {},
				'global.sendcartbyemail': {},
				'global.dynamicpromotion': {},
				'global.paypal': {},
				'global.multicurrency': {},
				'global.welcomepopup': {},
				'global.newsletter': {},
				'global.quickview': {},
				'global.shopthelook': {
					"calcHeightElement": false
				},
				'global.resetpassword': {},
				'global.scrollevents': {},
				'global.searchplaceholder': {
					initSearchPlaceholder: true
				},
				'global.simplesearch': {},
				'global.simplesubscription': {},
				'newsletter.handlepopup': {},
				'global.toggler': {},
				'global.tooltips': {},
				'global.youtubeImgPlay': {},
				'global.headerwishlist': {},
				'global.producttile': {},
				'global.bonusproducts': {},
				'global.recommendations': {},
				'global.modal': {},
				'global.spinbar': {},
				'global.warning': {},
				'global.history': {},
				'global.session': {},
				'global.images': {},
				'global.zipresolver': {},
				'global.scroll': {},
				'global.inputfield': {},
				'global.community': {}
			}
		},
		storefront: {
			components: {}
		},
		search: {
			components: {
				'search.priceslider': {},
				'cluster.anchorback': {},
				'search.anchorback': {},
				'search.compare': {},
				'global.wishlistblock': {},
				'global.stickykit': {
					recalcOn: 'header.change',
					'.js-refinements-sticky': {
						options: {
							recalc_every: 4,
							offset_top: 0,
							spacer: false
						}
					}
				}
			}
		},
		cart: {
			components: {}
		},
		compare: {
			components: {}
		},
		customerservice: {
			components: {
				'global.customerservice': {},
				'customerservice.contactus': {},
				'customerservice.newsletter': {},
				'global.scrollToAnchor': {}
			}
		},
		product: {
			components: {
				'global.recommendationblock': {},
				'global.socials': {},
				'global.sendToFriend': {}
			}
		},
		orderhistory: {
			components: {
				'account.orderhistory': {},
				'account.returnproducts': {},
				'account.ordersaccordion': {},
				'account.navigation': {}
			}
		},
		returnauthorization: {
			components: {
				'account.returnproducts': {}
			}
		},
		account: {
			components: {
				'account.paymentinstruments': {},
				'account.addresses': {},
				'account.newsletter': {},
				'account.navigation': {},
				'global.creditcard': {}
			},
			pages: {
				orderhistory: {
					components: {
						'account.orderhistory': {},
						'account.returnproducts': {}
					}
				},
				returnauthorization: {
					components: {
						'account.returnproducts': {}
					}
				}
			}
		},
		loginpopup: {
			components: {
				'account.loginiframe': {}
			}
		},
		wishlist: {
			components: {
				'global.socials': {},
				'global.sendToFriend': {},
				'account.navigation': {}
			}
		},
		registry: {
			components: {}
		},
		checkout: {
			components: {
				'global.recommendationblock': {},
				'global.paypal': {},
				'global.deco': {},
				'global.stickykit': {
					recalcOn: 'cart.shippingCountryChange cart.updateModels',
					'.js-cart_table-header': {}
				},
				'global.creditcard': {},
				'global.autocomplete': {},
				'global.klarna': {},
				'global.ups': {},
				'global.callmeback': {},
				'global.customerdata': {},
				'global.wishlistblock': {}
			}
		},
		favorites: {
			components: {}
		},
		mysamples: {
			components: {}
		},
		giftcertpurchase: {
			components: {
				'account.giftcertpurchase': {},
				'giftcertpurchase.custom': {}
			}
		},
		splashpage: {
			components: {
				'global.splashpage': {},
				'global.firstvisitbanner': {
					enabled: false
				},
				'global.newsletter': {
					enabled: false
				}
			}
		}
	};

	if (app.util.isGooglePaySupported()) {
		componentsConfig.global.components['global.googlepay'] = {};
	}

	if (app.util.isApplePaySupported()) {
		componentsConfig.global.components['global.applepay'] = {};
	}

	// publish app.components.config.global
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.global = componentsConfig;
})((window.app = window.app || {}), jQuery);
